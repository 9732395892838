import { Component, OnInit } from '@angular/core';
import { ContentService } from '../service/content.service';

@Component({
  selector: 'app-category-list',
  templateUrl: './category-list.component.html',
  styleUrls: ['./category-list.component.css']
})
export class CategoryListComponent implements OnInit {

  catListData: any;
  catContent = [];

  page = 1;
  count = 0;
  tableSize = 10;
  tableSizes = [10, 1];

  constructor(private categoryList: ContentService) { }

  ngOnInit(): void {
    this.getData();
  }

  getData() {
    this.catListData = this.categoryList.getPageContent();
    this.catContent = this.catListData.products;

    // console.log(this.catListData);
    // console.log(this.catContent);
  }

  onTableDataChange(event){
    this.page = event;
    this.catContent = this.catContent;
  }

  onTableSizeChange(event): void {
    this.tableSize = event.target.value;
    this.page = 1;
    this.catContent  = this.catContent;
  }

  sort(data) {
    this.catContent.sort((a, b) => {
      const fa = a.name.toLowerCase();
      const fb = b.name.toLowerCase();

      switch (data){
        case 'DESC': {
          if (fa > fb) {
            return -1;
          }
          if (fa < fb) {
            return 1;
          }
          break;
        }
        case 'ASC': {
            if (fa < fb) {
              return -1;
            }
            if (fa > fb) {
              return 1;
            }
            break;
        }
        default: {
          return 0;
        }
      }
    });
  }

}
