import { Component, OnInit } from '@angular/core';
import { ContentService } from '../service/content.service';
import { SiteInfoService } from '../service/site-info.service';

@Component({
  selector: 'app-tabs',
  templateUrl: './tabs.component.html',
  styleUrls: ['./tabs.component.css']
})
export class TabsComponent implements OnInit {

  active = 1;

  tabData: any;
  viewTabData: any;
  info: any;

  constructor(private tabContent: ContentService) { }

  ngOnInit(): void {
    this.tabData = this.tabContent.getPageContent();
    this.viewTabData = this.tabData.product;
    console.log(this.tabData.product);
  }

}
