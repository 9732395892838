
<div class="col-sm">
    <form #f="ngForm" (ngSubmit)="submit(f)">
        <div class="form-group">
            <input required type="text" ngModel id="name" name="name" class="form-control" placeholder="Name" #name="ngModel">
            <div class="alert alert-danger" *ngIf="name.touched && !name.valid">Name is required.</div>
        </div>
        <div class="form-group">
            <input required type="text" ngModel id="position" name="position" class="form-control" placeholder="Position" #position="ngModel">
            <div class="alert alert-danger" *ngIf="position.touched && !position.valid">Position is required.</div>
        </div>
        <div class="form-group">
            <input required type="text" ngModel id="company_name" name="company_name" class="form-control" placeholder="Company name" #company_name="ngModel">
            <div class="alert alert-danger" *ngIf="company_name.touched && !company_name.valid">Company name is required.</div>
        </div>
        <div class="form-group">
            <input required type="email" ngModel id="email" name="email" class="form-control" placeholder="Email" #email="ngModel" email="true">
            <div class="alert alert-danger" *ngIf="email.touched && !email.valid ">Invalid email.</div>
        </div>
        <div class="form-group">
            <input required type="text" ngModel id="telephone" name="telephone" class="form-control" placeholder="Telephone number" #telephone="ngModel">
            <div class="alert alert-danger" *ngIf="telephone.touched && !telephone.valid">Telephone number is required.</div>
        </div>
        <div class="form-group">
            <input type="text" name="fax" class="form-control" placeholder="Fax" ngModel>
        </div>
        <div class="form-group">
            <textarea required class="form-control" ngModel id="message" name="message" placeholder="Message" #message="ngModel"></textarea>
            <div class="alert alert-danger" *ngIf="message.touched && !message.valid">Message is required.</div>
        </div>
        <div *ngIf="onSuccess == true" id="text-success"><p>Your inquiry has been successfully submitted.</p></div>
        <div *ngIf="captchaVisibility === 1" appRecaptcha (recaptchaSuccess)="onRecaptchaSuccess($event)" (recaptchaExpired)="onRecaptchaExpired($event)" (recaptchaError)="onRecaptchaError($event)"></div><br>
        <button class="btn btn-secondary shadow" [disabled]="isDisable">Submit</button>
    </form>
</div>

