<!-- <div class="container">
  <h5>Category</h5>
  <ul ngbNav #nav="ngbNav" class="nav-tabs">
    <li ngbNavItem>
      <a ngbNavLink>PVC, PU BELTS PRODUCTS</a>
      <ng-template ngbNavContent>
        <p>Product here...</p>
      </ng-template>
    </li>
    <li ngbNavItem>
      <a ngbNavLink>PABRICATION BELT MACHINES</a>
      <ng-template ngbNavContent>
        <p>Product here...</p>
      </ng-template>
    </li>
  </ul>

  <div [ngbNavOutlet]="nav" class="mt-2"></div>
</div> -->

<div class="container">
 <div class="row">
  <div class="col-12">
   <nav class="navbar navbar-expand-lg navbar-light bg-light rounded">
    <!-- <button class="navbar-toggler" type="button" (click)="isCollapsed = !isCollapsed" [attr.aria-expanded]="!isCollapsed" data-toggle="collapse" data-target="#navbar" aria-controls="navbars" aria-expanded="false" aria-label="Toggle navigation">
					<span class="navbar-toggler-icon"></span>
		  </button> -->
    <div class="" id="navbar">
     <ul class="navbar-nav mr-auto">
      <li ngbDropdown class="nav-item dropdown megamenu-li">
       <a  ngbDropdownToggle class="nav-link dropdown-toggle" id="dropdown01" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">PRODUCTS CATEGORY</a>
       <div ngbDropdownMenu class="dropdown-menu megamenu" aria-labelledby="dropdown01">
        <div class="row">
         <div class="col" *ngFor="let content of categoryContent; let i=index;">
          <h6>{{ content.label }}</h6>
          <hr>
          <a  *ngFor="let child of content.child_categories | keyvalue" class="dropdown-item" [routerLink]="child.value.product_view_url">{{ child.value.label }}</a>
         </div>
        </div>
       </div>
      </li>
     </ul>
    </div>
   </nav>
  </div>
 </div>
</div>
