import { Component, OnInit } from '@angular/core';
import { ContentService } from '../service/content.service';

@Component({
  selector: 'app-brands',
  templateUrl: './brands.component.html',
  styleUrls: ['./brands.component.css']
})
export class BrandsComponent implements OnInit {
  brandsData: any;
  extractBrands = [];
  brandsContent = [];
  brandOdrer = '';

  brandsDataForTesting = [];

  page = 1;
  count = 0;
  tableSize = 10;
  tableSizes = [10, 1];

  constructor(private serviceCont: ContentService) { }

  ngOnInit(): void {
    this.getBrands();
    this.sort(this.brandOdrer);
  }

  getBrands() {
    this.brandsData = this.serviceCont.getPageContent();

    for (const brand of this.brandsData) {
      if (brand.name === 'Brand Listing') {
        this.brandsData = brand;
        this.serviceCont.pageContents(this.brandsData.view_url)
          .subscribe(resdata => {
            this.extractBrands.push(resdata);
            this.brandsContent = this.extractBrands[0].brands;
            this.brandOdrer = this.extractBrands[0].paginator.direction;
          });
      }
    }
  }

  onTableDataChange(event){
    this.page = event;
    this.brandsContent = this.brandsContent;
  }

  onTableSizeChange(event): void {
    this.tableSize = event.target.value;
    this.page = 1;
    this.brandsContent  = this.brandsContent;
  }

  sort(data: any) {
    this.brandsContent.sort((a, b) => {
      const fa = a.name.toLowerCase();
      const fb = b.name.toLowerCase();

      switch (data){
        case 'DESC': {
          if (fa > fb) {
            return -1;
          }
          if (fa < fb) {
            return 1;
          }
          break;
        }
        case 'ASC': {
            if (fa < fb) {
              return -1;
            }
            if (fa > fb) {
              return 1;
            }
            break;
        }
        default: {
          return 0;
        }
      }
    });
  }

}
