<div class="container">
  <div class="row">
      <div class="col-md-12">
          <div class="error-template text-center">
              <h1>
                  Oops!</h1>
              <h2>
                  404 Not Found</h2>
              <div class="error-details">
                  Sorry, an error has occured, Requested page not found!
              </div>
              <div class="error-actions">
                  <a href="http://localhost:4200/" class="btn btn-secondary btn-lg"><span class="glyphicon glyphicon-home"></span>
                      Take Me Home </a>
              </div>
          </div>
      </div>
  </div>
</div>
