
<div class="article-section">
  <div class="art">
    <div *ngIf="themeStyle === 'impian_emas'; then thenBlock else elseBlock"></div>
    <ng-template #thenBlock>
      <section id="impian-article" *ngFor="let article of articleContent">
        <div id="impian-section">
          <h2>{{ article.title}}</h2>
        <div [innerHTML]="article.body"></div>
        </div>
      </section>
    </ng-template>
    <ng-template #elseBlock>
      <section id="article" *ngFor="let article of articleContent">
        <h2>{{ article.title}}</h2>
        <p class="article-desc" class="text-justify" [innerHTML]="article.body "></p>
      </section>
    </ng-template>
  </div>
</div>

