<div class="text-center">
  <div *ngIf="themeStyle === 'impian_emas'; else elseBlock">
    <ngb-carousel>
        <ng-template
        *ngFor="let caro of caroExtracted"
        ngbSlide>
          <div class="picsum-img-wrapper impian-hero">
            <img [src]="caro.image_url" alt="Angular Carousel 1" id="custom-img">
          </div>
          <!-- <div>
            <a class="btn btn-primary btn-lg" href="#" role="button" id="hero-btn">Learn More &raquo;</a>
          </div> -->
          <div class="carousel-caption">
            <h3>{{ caro.title }}</h3>
            <p>{{ caro.body }}</p>
          </div>
        </ng-template>
      </ngb-carousel>
  </div>
  <ng-template #elseBlock>
    <ng-template [ngIf]="themeStyle === 'keefatt'">
      <ngb-carousel>
        <ng-template
        *ngFor="let caro of caroExtracted"
        ngbSlide>
          <div class="picsum-img-wrapper">
            <img [src]="caro.image_url" alt="Angular Carousel 1" id="custom-img">
          </div>
          <div class="carousel-caption" id="caro-mobile">
            <h3>{{ caro.title }}</h3>
            <p>{{ caro.body }}</p>
          </div>
          <div class="box stack-top carousel-caption" id="caro-desktop">
            <h3>{{ caro.title }}</h3>
          </div>
        </ng-template>
      </ngb-carousel>
    </ng-template>

    <ng-template [ngIf]="themeStyle !== 'keefatt'">
      <ngb-carousel>
        <ng-template
        *ngFor="let caro of caroExtracted"
        ngbSlide>
          <div class="picsum-img-wrapper">
            <img [src]="caro.image_url" alt="Angular Carousel 1" id="custom-img">
          </div>
          <div class="carousel-caption">
            <h3>{{ caro.title }}</h3>
            <p>{{ caro.body }}</p>
          </div>
        </ng-template>
      </ngb-carousel>
    </ng-template>
  </ng-template>
</div>
