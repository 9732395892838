import { Component, Inject, OnInit } from '@angular/core';
import { Title, Meta} from '@angular/platform-browser';
import { SiteInfoService } from './service/site-info.service';
import { DOCUMENT } from '@angular/common';
import { ContentService } from './service/content.service';
import { LanguagesService } from './service/languages.service';
import { FacebookService, InitParams } from 'ngx-facebook';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit{

  title = '';
  metas: any;
  favIcon: HTMLLinkElement = document.querySelector('#favIcon');

  themeStyle = '';
  themeCode = '';

  constructor(
    @Inject(DOCUMENT)private dom: Document,
    private titleService: Title,
    private metaService: Meta,
    private pageTags: SiteInfoService,
    private contentService: ContentService,
    private facebookService: FacebookService) {}

  ngOnInit() {
    this.pageTags.getSiteInfo()
      .subscribe(data => {
        this.pageTags.setSiteInfo(data);
        this.metas = data;
        // console.log(this.metas.site_info.theme.code);
        this.themeStyle = this.metas.site_info.theme.theme_url;
        this.themeCode = this.metas.site_info.theme.code;
        // console.log(this.themeCode);
        this.contentService.setTheme(this.metas.site_info.theme.code);

        if (this.themeCode === 'impian_emas') {
          document.body.style.backgroundColor = '#e8a713';
        }

        this.loadStyle(this.themeStyle);
        this.title = this.metas.site_info.title;
        this.dom.documentElement.lang = this.metas.site_info._locale;
        this.favIcon.href = this.metas.site_info.meta_icon_url;
        this.favIcon.type = this.metas.site_info.meta_icon_type;
        this.titleService.setTitle(this.title);
        this.metaService.addTags([
          { name: 'meta_title', content: this.title},
          { name: 'meta_descrition', content: this.metas.site_info.meta_description},
          { name: 'theme', content: this.metas.site_info.theme.name}
        ]);
      });
    this.initFacebookService();
  }

  loadStyle(styleName: string) {
    const theme = styleName + '/css/main.css';

    const head = this.dom.getElementsByTagName('head')[0];
    const themeLink = this.dom.getElementById(
      'client-theme'
    ) as HTMLLinkElement;
    if (themeLink) {
      themeLink.href = styleName;
    } else {
      const style = this.dom.createElement('link');
      style.id = 'client-theme';
      style.rel = 'stylesheet';
      style.href = `${theme}`;
      head.appendChild(style);
    }
  }

  private initFacebookService(): void {
    const initParams: InitParams = { xfbml: true, version: 'v3.2' };
    this.facebookService.init(initParams);
  }
}
