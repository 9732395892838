<section>
  <div class="event-news">
    <div class="content-desktop news-desktop">
      <div id="news-event">
        <div class="row text-center" id="event">
          <div class="col event-cust">
            <img src="assets/image/new-year-ig.jpg" alt="">
          </div>
          <div class="col event-cust article">
            <div id="news">
              <div class="text-center art-cust" [innerHTML]="(newsContents.body |  slice:0:300) + '...'">
              </div>
              <a [routerLink]="newsContents.view_url">Read More >></a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="content-mobile news-article">
      <div id="news-event">
        <div class="row text-center" id="event">
          <div class="col-lg event-cust article">
            <div id="article-cust">
              <div class="text-center">
                <!-- <h3 class="text-center">
                  {{ artitle }}
                </h3> -->
                <!-- <p class="text-justify text-center" [innerHTML]="artbody | slice:0:140"></p> -->
                <div class="text-center art-cust" [innerHTML]="(newsContents.body |  slice:0:300) + '...'">
                </div>
                <a [routerLink]="newsContents.view_url">Read More >></a>
                <!-- <a [routerLink]="arturl" class="mobile-link">Read More >></a> -->
              </div>
            </div>
          </div>
          <div class="col-lg event-cust">
            <img src="assets/image/new-year-ig.jpg" alt="">
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
