<div *ngIf="themeStyle !== 'impian_emas' ; else elseBlock">
  <h5 class="text-center">{{ addressTitle }}</h5>
  <strong> Address</strong>
  <p>{{ mainAddress }}</p>
  <strong> Contact</strong>
  <p>{{ contact1 }} / {{ contact2 }}</p>
  <strong> Email</strong>
  <p>{{ email }}</p>

  <!-- <span><i class="fa fa-address-card fa-1x"></i></span><strong> Address</strong>
  <p>{{ mainAddress }}</p>
  <span><i class="fa fa-phone fa-1x"></i></span><strong> Contact</strong>
  <p>{{ contact1 }} / {{ contact2 }}</p>
  <span><i class="fa fa-envelope-open fa-1x"></i></span><strong> Email</strong>
  <p>{{ email }}</p> -->
</div>
<ng-template #elseBlock>
  <div id="impian-address" class="text-left" id="add-desktop">
    <p>Address : No.11, Jalan Abcde Fghijk,<br>Taman Zyxwvu Tsrqpo,<br>84200 Serendah, Selangor, Malaysia.</p>
    <p>Phone :  +6012-345 6789</p>
    <p>Hours : Mon - Fri, 9am to 6pm</p>
    <p>E - mail : sales@abcdefghijkl.com</p>
    <span class="social">
      <i class="fa fa-facebook-f fa-1x"></i>
      <i class="fa fa-instagram fa-1x"></i>
    </span>
  </div>

  <div id="impian-address" class="text-center" id="add-mobile">
    <p>Address : No.11, Jalan Abcde Fghijk,<br>Taman Zyxwvu Tsrqpo,<br>84200 Serendah, Selangor, Malaysia.</p>
    <p>Phone :  +6012-345 6789</p>
    <p>Hours : Mon - Fri, 9am to 6pm</p>
    <p>E - mail : sales@abcdefghijkl.com</p>
    <span class="social">
      <i class="fa fa-facebook-f fa-1x"></i>
      <i class="fa fa-instagram fa-1x"></i>
    </span>
  </div>
</ng-template>

