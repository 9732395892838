import { Component, OnInit} from '@angular/core';
import { ContentService } from '../service/content.service';


@Component({
  selector: 'app-product',
  templateUrl: './product.component.html',
  styleUrls: ['./product.component.css']
})
export class ProductComponent implements OnInit {

  productData: any;
  prodList = [];
  prodContent = [];
  arrCont = [];
  mainCont = [];
  brand = [];
  listOrder = '';
  searchProd = false;

  page = 1;
  count = 0;
  tableSize = 10;
  tableSizes = [10, 1];

  constructor(private productService: ContentService) { }

  ngOnInit(): void {
    window.scroll(0, 0);
    this.productData = [];
    this.mainCont = [];
    this.productService.pageContents('/products')
      .subscribe(resdata => {
        this.productData = resdata;
        console.log(this.productData);
        this.prodList = this.productData.products;
        this.listOrder = this.productData.paginator.direction;
        // console.log(this.prodList);

        for (let i = 0; i < this.prodList.length; i++) {
          this.prodContent = this.prodList[i].product_images;
          this.arrCont = this.arrCont.concat(this.prodList[i].product_images);
          this.mainCont.push(Object.assign(this.arrCont[i], this.prodList[i]));
          this.brand.push(this.productData.products[i].brand);
        }
        // console.log(this.mainCont);
      });
  }

  onTableDataChange(event){
    this.page = event;
    this.mainCont = this.mainCont;
  }

  onTableSizeChange(event): void {
    this.tableSize = event.target.value;
    this.page = 1;
    this.mainCont  = this.mainCont;
  }

  sort(data) {
    this.mainCont.sort((a, b) => {
      const fa = a.name.toLowerCase();
      const fb = b.name.toLowerCase();

      switch (data){
        case 'DESC': {
          if (fa > fb) {
            return -1;
          }
          if (fa < fb) {
            return 1;
          }
          break;
        }
        case 'ASC': {
            if (fa < fb) {
              return -1;
            }
            if (fa > fb) {
              return 1;
            }
            break;
        }
        default: {
          return 0;
        }
      }
    });
  }

  onSubmit(f) {
    this.searchProduct(f.form.controls.prodKey.value);
  }

  searchProduct(prodKey) {
    if (prodKey !== '') {
      this.productData = [];
      this.mainCont = [];
      this.productService.searcProduct(prodKey)
      .subscribe(resdata => {
        // console.log(resdata);
        this.productData.push(resdata);
        if (this.productData[0].products.length !== 0) {
          this.prodList = this.productData[0].products;
          this.listOrder = this.productData[0].paginator.direction;

          for (let i = 0; i < this.prodList.length; i++) {
          this.prodContent.push(this.prodList[i].product_images);
          this.arrCont = this.arrCont.concat(this.prodList[i].product_images);
          this.mainCont.push(Object.assign(this.arrCont[i], this.prodList[i]));
        }
          this.sort(this.listOrder);
          // console.log(this.productData[0].products);
        }
      });
    } else {
      this.ngOnInit();
    }
  }
}
