
<header *ngIf="menuVisibility === 1">
  <div *ngIf="themeStyle ==='impian_emas'; then thenBlock else elseBlock"></div>
  <ng-template #thenBlock>
    <div class="content-desktop" id="header-logo">
      <nav id="main-nav" class="navbar navbar-expand-md navbar-light fixed-top border-bottom" [class]="menuClass">
          <img id="logo" src="assets/image/logo.png" alt="">
        <div class="collapse navbar-collapse" id="navbarCollapse" [ngbCollapse]="isCollapsed">
          <ul class="navbar-nav ml-auto impian-desk-nav">
            <!-- <li ngbDropdown
            *ngFor="let links of headerLinks"
            class="nav-item impian-nav-item">
            <div *ngIf="links.level_two_links.length > 0;then content else other_content"></div>
            <ng-template #content>
              <a class="nav-link" tabindex="0" ngbDropdownToggle id="navbarDropdown3" role="button">
                {{ links.name }}
              </a>
              <div ngbDropdownMenu aria-labelledby="navbarDropdown3" class="dropdown-menu impian-sub-link sub-level-menu">
                <a *ngFor="let subLinks of indexTwoLinks" ngbDropdownItem [routerLink]="subLinks.url">{{ subLinks.name }}</a>
              </div>
            </ng-template>
            <ng-template #other_content><a class="nav-link" [class.my_class] = "themeStyle === 'keefatt'" [routerLink]="links.url">{{ links.name }}<span class="sr-only">(current)</span></a></ng-template>
            </li> -->

            <li ngbDropdown
            *ngFor="let links of headerLinks"
            class="nav-item impian-nav-item">
            <div *ngIf="links.level_two_links.length > 0;then content else other_content"></div>
            <ng-template #content>
              <div class="text-center">
              <a class="nav-link" tabindex="0" ngbDropdownToggle id="navbarDropdown3" role="button">
                {{ links.name }}
              </a>
              <div ngbDropdownMenu aria-labelledby="navbarDropdown3" class="dropdown-menu impian-sub-link sub-level-menu sub-3 sub-main">
                <div class="d-inline-block" ngbDropdown #myDrop="ngbDropdown" *ngFor="let subLinks of links.level_two_links">
                  <div *ngIf="subLinks.level_three_links.length > 0; then thenBlock else elseBlock"></div>
                  <ng-template #thenBlock>
                    <a id="dropdownManual" ngbDropdownToggle>{{ subLinks.name }}</a>
                    <div class="dropdown-menu impian-sub-link sub-level-menu sub-3 sub-4" ngbDropdownMenu aria-labelledby="dropdownManual">
                      <div class="d-inline-block" ngbDropdown #myDrop="ngbDropdown" *ngFor="let subLink3 of subLinks.level_three_links">
                        <div *ngIf="subLink3.level_four_links.length > 0; then thenBlock else elseBlock"></div>
                        <ng-template #thenBlock>
                          <a id="dropdownManual" ngbDropdownToggle>{{ subLink3.name }}</a>
                          <div class="dropdown-menu impian-sub-link sub-level-menu sub-3 sub-4 sub-5"ngbDropdownMenu aria-labelledby="dropdownManual">
                            <div class="d-inline-block" ngbDropdown #myDrop="ngbDropdown" *ngFor="let subLink4 of subLink3.level_four_links">
                              <div *ngIf="subLink4.level_five_links.length > 0; then thenBlock else elseBlock"></div>
                              <ng-template #thenBlock>
                                <div id="test">
                                  <a id="dropdownManual" ngbDropdownToggle>{{ subLink4.name }}</a>
                                </div>
                                <div class="dropdown-menu impian-sub-link sub-level-menu sub-3 sub-4" ngbDropdownMenu aria-labelledby="dropdownManual">
                                  <div class="d-inline-block" ngbDropdown #myDrop="ngbDropdown" *ngFor="let subLink5 of subLink4.level_five_links">
                                    <a class="" ngbDropdownItem [routerLink]="subLink5.url">{{ subLink5.name }} teryo</a>
                                  </div>
                                </div>
                              </ng-template>
                              <ng-template #elseBlock>
                                <a class="" ngbDropdownItem [routerLink]="subLink4.url">{{ subLink4.name }}</a>
                              </ng-template>
                            </div>
                          </div>
                        </ng-template>
                        <ng-template #elseBlock>
                          <a class="" ngbDropdownItem [routerLink]="subLink3.url">{{ subLink3.name }}</a>
                        </ng-template>
                      </div>
                    </div>
                  </ng-template>
                  <ng-template #elseBlock><a class="" ngbDropdownItem [routerLink]="subLinks.url">{{ subLinks.name }}</a></ng-template>
                </div>
              </div>
            </div>
            </ng-template>
            <ng-template #other_content><a class="nav-link" [class.my_class] = "themeStyle === 'keefatt'" [routerLink]="links.url">{{ links.name }}<span class="sr-only">(current)</span></a></ng-template>
            </li>

            <li class="impian-nav-item">
              <a class="nav-link" >
                <span ><i class="fa fa-bars"></i></span>
              </a>
            </li>
          </ul>
        </div>
        <div
        *ngIf="loadingSpinner"
        class="spinner-border text-center"></div>
      </nav>
    </div>
    <div class="content-mobile" id="header-mobile">
      <nav id="mobile-nav" class="navbar navbar-expand-md navbar-light fixed-top border-bottom" [class]="menuClass">
        <a class="navbar-brand" [routerLink]="" id="header-mobile">
          <img src="assets/image/logo.png" alt="">
        </a>
        <div class="icon-bar">
          <a href="#"><span><i class="fa fa-bars"></i></span></a>
          <a href="#"><span><i class="fa fa-heart"></i></span></a>
          <a href="#"><span id="bag"><i class="fa fa-shopping-bag"></i></span></a>
          <a href="#"><span><i class="fa fa-user"></i></span></a>
          <a href="#"><span><i class="fa fa-search"></i></span></a>
        </div>
        <div
        *ngIf="loadingSpinner"
        class="spinner-border text-center"></div>
      </nav>
    </div>
  </ng-template>
  <ng-template #elseBlock>
    <nav id="main-nav" class="navbar navbar-expand-md navbar-light fixed-top border-bottom" [class]="menuClass">
      <a class="navbar-brand" [routerLink]="" id="impian-logo">
          <img id="logo" [src]="headerLogo" width=220 heigth=28.2 alt="">
      </a>
      <button class="navbar-toggler" type="button" (click)="isCollapsed = !isCollapsed" [attr.aria-expanded]="!isCollapsed" data-toggle="collapse" data-target="#navbarCollapse" aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarCollapse" [ngbCollapse]="isCollapsed">
        <ul class="navbar-nav ml-auto">

          <!-- <li ngbDropdown
          *ngFor="let links of headerLinks"
          class="nav-item">
          <div *ngIf="links.level_two_links.length > 0;then content else other_content"></div>

          for level two links
          <ng-template #content>
            <a class="nav-link" tabindex="0" ngbDropdownToggle id="navbarDropdown3" role="button">
              {{ links.name }}
            </a>
            <div ngbDropdownMenu aria-labelledby="navbarDropdown3" class="dropdown-menu impian-sub-link sub-level-menu">
              <a *ngFor="let subLinks of indexTwoLinks" ngbDropdownItem [routerLink]="subLinks.url">{{ subLinks.name }}</a>
            </div>
          </ng-template>

          <ng-template #other_content><a class="nav-link" [class.my_class] = "themeStyle === 'keefatt'" [routerLink]="links.url">{{ links.name }}<span class="sr-only">(current)</span></a></ng-template>
          </li> -->

          <!-- level 5 links -->
          <li ngbDropdown
            *ngFor="let links of headerLinks"
            class="nav-item impian-nav-item d-inline-block" >
            <div *ngIf="links.level_two_links.length > 0;then content else other_content"></div>
            <ng-template #content>
              <div class="text-center">
              <a class="nav-link" tabindex="0" ngbDropdownToggle id="navbarDropdown3" role="button">
                {{ links.name }}
              </a>
              <div ngbDropdownMenu aria-labelledby="navbarDropdown3" class="dropdown-menu impian-sub-link sub-level-menu sub-3 sub-main">
                <div class="d-inline-block" ngbDropdown #myDrop="ngbDropdown" *ngFor="let subLinks of links.level_two_links">
                  <div *ngIf="subLinks.level_three_links.length > 0; then thenBlock else elseBlock"></div>
                  <ng-template #thenBlock>
                    <a id="dropdownManual" ngbDropdownToggle>{{ subLinks.name }}</a>
                    <div class="dropdown-menu impian-sub-link sub-level-menu sub-3" ngbDropdownMenu aria-labelledby="dropdownManual">
                      <div class="d-inline-block" ngbDropdown #myDrop="ngbDropdown" *ngFor="let subLink3 of subLinks.level_three_links">
                        <div *ngIf="subLink3.level_four_links.length > 0; then thenBlock else elseBlock"></div>
                        <ng-template #thenBlock>
                          <a id="dropdownManual" ngbDropdownToggle>{{ subLink3.name }}</a>
                          <div class="dropdown-menu impian-sub-link sub-level-menu sub-3" ngbDropdownMenu aria-labelledby="dropdownManual">
                            <div class="d-inline-block" ngbDropdown #myDrop="ngbDropdown" *ngFor="let subLink4 of subLink3.level_four_links">
                              <div *ngIf="subLink4.level_five_links.length > 0; then thenBlock else elseBlock"></div>
                              <ng-template #thenBlock>
                                <a id="dropdownManual" ngbDropdownToggle>{{ subLink4.name }}</a>
                                <div class="dropdown-menu impian-sub-link sub-level-menu sub-3" ngbDropdownMenu aria-labelledby="dropdownManual">
                                  <div class="d-inline-block" ngbDropdown #myDrop="ngbDropdown" *ngFor="let subLink5 of subLink4.level_five_links">
                                    <a class="" ngbDropdownItem [routerLink]="subLink5.url">{{ subLink5.name }}</a>
                                  </div>
                                </div>
                              </ng-template>
                              <ng-template #elseBlock>
                                <a class="" ngbDropdownItem [routerLink]="subLink4.url">{{ subLink4.name }}</a>
                              </ng-template>
                            </div>
                          </div>
                        </ng-template>
                        <ng-template #elseBlock>
                          <a class="" ngbDropdownItem [routerLink]="subLink3.url">{{ subLink3.name }}</a>
                        </ng-template>
                      </div>
                    </div>
                  </ng-template>
                  <ng-template #elseBlock><a class="" ngbDropdownItem [routerLink]="subLinks.url">{{ subLinks.name }}</a></ng-template>
                </div>
              </div>
            </div>
            </ng-template>
            <ng-template #other_content><a class="nav-link" [class.my_class] = "themeStyle === 'keefatt'" [routerLink]="links.url">{{ links.name }}<span class="sr-only">(current)</span></a></ng-template>
            </li>
          <!-- end -->

          <li class="nav-item">
            <div class="d-inline-block" ngbDropdown #myDrop="ngbDropdown" id="lang-btn">
              <button class="btn btn-outline-primary mr-2" id="dropdownManual" ngbDropdownAnchor (focus)="myDrop.open()">Lang</button>
              <div ngbDropdownMenu aria-labelledby="dropdownManual">
                <button *ngFor="let lang of languages" ngbDropdownItem (click)="changeLang(lang.code)"><span id="icon"><img class="rounded" [src]="lang.icon" /> </span> {{ lang.name }}</button>
              </div>
            </div>
          </li>
        </ul>
      </div>
      <div
      *ngIf="loadingSpinner"
      class="spinner-border text-center"></div>
    </nav>
  </ng-template>
<header>


