import { Component, OnInit } from '@angular/core';
import { ContentService } from '../service/content.service';

@Component({
  selector: 'app-brand-view',
  templateUrl: './brand-view.component.html',
  styleUrls: ['./brand-view.component.css']
})
export class BrandViewComponent implements OnInit {

  viewBrandCont: any;
  viewBrandDesc = '';
  viewBrandTitle = '';
  imgUrl = '';
  viewResult = [];

  constructor(private brandService: ContentService) { }

  ngOnInit(): void {
    this.viewBrand();
  }

  viewBrand() {
    this.viewBrandCont = this.brandService.getPageContent();
    this.viewBrandTitle = this.viewBrandCont.brand.name;
    this.viewBrandDesc = this.viewBrandCont.brand.description;
    this.imgUrl = this.viewBrandCont.brand.image;
  }

}
