import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError } from 'rxjs/operators';
import { ApiError } from '../common/api-error';
import { GlobalConstants } from '../common/global-constants';

@Injectable({
  providedIn: 'root'
})
export class FooterService {

  endpoint = GlobalConstants.apiURL + '/footer?token=' + GlobalConstants.apiToken;

  constructor(private httpClient: HttpClient, private apiError: ApiError) { }

  getFooter() {
    return this.httpClient.get(this.endpoint).pipe(
      catchError(this.apiError.handleError)
    );
  }
}
