import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { textChangeRangeIsUnchanged } from 'typescript';
import { ApiError } from '../common/api-error';
import { GlobalConstants } from '../common/global-constants';

@Injectable({
  providedIn: 'root'
})
export class ContentService {

  private sections: any;
  private category: any;
  private categories: any;
  private theme: any;
  private address: any;

  // for testing
  fakeData: any;

  constructor(
    private httpClient: HttpClient, private apiError: ApiError) { }

  // receive data from main content component
  setPageContent(data) {
    this.sections = data;
  }

  getPageContent() {
    return this.sections;
  }

  searcProduct(keyword) {
    return this.httpClient.get(GlobalConstants.apiURL + 'products/search?keywords=' + keyword + '&token=' + GlobalConstants.apiToken).pipe(
      catchError(this.apiError.handleError)
    );
  }

  getCategory(){
    // let category: any;
    // return category = of(GlobalConstants.category).pipe(
    //   map(x => x)
    // );
    return this.category;
  }

  setCategory(data: any) {
    this.category = data;
  }

  searchCategory(id) {
    return this.httpClient.get(GlobalConstants.apiURL + ' products/category/' + id + '?token=' + GlobalConstants.apiToken).pipe(
      catchError(this.apiError.handleError)
    );
  }

  getArticleList(){
    let articles: any;
    return articles = of(GlobalConstants.articles).pipe(
      map(x => x)
    );
  }

  searchArticleList(id){
    // return this.httpClient.get(GlobalConstants.apiURL + 'articles/' + id + '?token=' + GlobalConstants.apiToken).pipe(
    //   catchError(this.apiError.handleError)
    // );

    // this is just a fake data since there is no data yet for the article list api..
    let articles: any;
    return articles = of(GlobalConstants.articles).pipe(
      map(x => {
        // tslint:disable-next-line:prefer-for-of
        for (let i = 0; i < x.articles.length; i++) {
          if (x.articles[i].id === id) {
          return x.articles[i];
          }
        }
      })
    );
  }

  getBrands(){
    let brands: any;
    return brands = of(GlobalConstants.brands).pipe(
      map(x => x)
    );
  }

  searchBrands(id){
    // return this.httpClient.get(GlobalConstants.apiURL + 'brands/' + id + '?token=' + GlobalConstants.apiToken).pipe(
    //   catchError(this.apiError.handleError)
    // );

    let brands: any;
    return brands = of(GlobalConstants.brands).pipe(
      map(x => {
        // tslint:disable-next-line:prefer-for-of
        for (let i = 0; i < x.brands.length; i++) {
          if (x.brands[i].id === id) {
          return x.brands[i];
          }
        }
      })
    );
  }

  // this is just for testing the view product with category data....
  getViewProduct() {
    // tslint:disable-next-line:prefer-const
    let viewProd: any;
    return viewProd = of(GlobalConstants.viewProduct).pipe(
      map(x => x)
    );
  }

  pageContents(page) {
    return this.httpClient.get(GlobalConstants.apiURL  + page + '?token=' + GlobalConstants.apiToken).pipe(
      catchError(this.apiError.handleError)
    );
  }

  pageCategories(page: any) {
    return this.httpClient.get(GlobalConstants.apiURL  + page + '?token=' + GlobalConstants.apiToken).pipe(
      catchError(this.apiError.handleError)
    );
  }

  setPageCategories(data: any) {
    this.categories = data;
  }

  getPageCategories() {
    console.log(this.categories);
    // return this.categories;
  }

  setTheme(themeStyle: string) {
    this.theme = themeStyle;
  }

  getTheme() {
    return this.theme;
  }

  setAddress(address) {
    this.address = address;
  }

  getAddress() {
    return this.address;
  }
  paginator(page: string) {
    return this.httpClient.get(GlobalConstants.apiURL  + page + '&token=' + GlobalConstants.apiToken).pipe(
      catchError(this.apiError.handleError)
    );
  }

}
