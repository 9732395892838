import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ContactService } from '../service/contact.service';
import { ContentService } from '../service/content.service';
import { SiteInfoService } from '../service/site-info.service';

@Component({
  selector: 'app-form',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.css']
})
export class FormComponent implements OnInit {

  sendData: any;
  successData: any;
  onSuccess = false;
  contactContent: any;
  currentPage = '';
  pageTitle = '';
  contSections = {};
  addresSection = [];
  captchaVisibility = 0;
  siteInfo: any;
  isDisable = true;
  token: string;

  onRecaptchaSuccess(token: string) {
    this.isDisable = false;
    this.token = token;
  }

  onRecaptchaExpired(e) {
      this.isDisable = true;
  }

  onRecaptchaError(e) {
    this.isDisable = true;
  }

  constructor(
    private contactService: ContactService,
    private contContent: ContentService,
    private visibility: SiteInfoService) { }

  ngOnInit(): void {
    this.onSuccess = false;
    this.getContact();
    this.visibility.getSiteInfo()
    .subscribe(resdata => {
      this.siteInfo = resdata;
      this.captchaVisibility = this.siteInfo.site_info.grecaptcha_contactus;
    });
  }

  submit(f: NgForm) {
    const cont = f.form.controls;
    if (cont.name.value === '') {
      document.getElementById('name').focus();
    } else if (cont.position.value === '') {
      document.getElementById('position').focus();
    } else if (cont.company_name.value === '') {
      document.getElementById('company_name').focus();
    } else if (cont.email.value === '') {
      document.getElementById('email').focus();
    } else if (cont.telephone.value === '') {
      document.getElementById('telephone').focus();
    } else if (cont.message.value === '') {
      document.getElementById('message').focus();
    } else {
      if (this.isDisable !== true ) {
        this.sendData = this.contactService.sendContact(f.value)
        .subscribe( resdata => {
          this.successData = resdata;
          this.onSuccess = this.successData.success;
          setTimeout(() => {
            this.onSuccess = false;
       }, 10000);
          f.resetForm();
        });
      }
    }
  }

  getContact() {
    this.contactContent = this.contContent.getPageContent();
    this.addresSection = this.contactContent[0].contents;
  }
}
