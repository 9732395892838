import { Component, OnInit } from '@angular/core';
import { ContentService } from '../service/content.service';

@Component({
  selector: 'app-category-menu',
  templateUrl: './category-menu.component.html',
  styleUrls: ['./category-menu.component.css']
})
export class CategoryMenuComponent implements OnInit {

  isCollapsed = true;
  transfer = [];
  categoryContent = [];
  childCategory = [];

  constructor(private categoryService: ContentService) { }

  ngOnInit(): void {
    this.categoryService.pageContents('/categories')
      .subscribe(resdata => {
        this.transfer.push(resdata);
        this.categoryContent = this.transfer[0].categories;
        // console.log(this.transfer[0].categories);

        // tslint:disable-next-line:prefer-for-of
        for (let i = 0; i < this.categoryContent.length; i++) {
          this.childCategory.push(this.categoryContent[i].child_categories);
        }
        // console.log(this.categoryContent);
      });
  }
}
