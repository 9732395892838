import { Component, OnInit } from '@angular/core';
import { FooterService } from '../service/footer.service';
import { SiteInfoService } from '../service/site-info.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {

  footerData: any;
  copyRight = '';
  footerLinks = [];
  footerClass = '';
  errSpinner = false;
  footerVisibility = 0;
  siteInfo: any;

  themeStyle = '';

  constructor(private data: FooterService, private visibility: SiteInfoService) { }

  ngOnInit(): void {
    this.errSpinner = false;

    this.visibility.getSiteInfo()
    .subscribe(resdata => {
      this.siteInfo = resdata;
      this.footerVisibility = this.siteInfo.site_info.footer_menu_visibility;
      this.themeStyle = this.siteInfo.site_info.theme.code;
      // console.log(this.siteInfo.site_info.theme.code);
    });

    this.footerData = this.data.getFooter()
      .subscribe(resdata => {
        this.footerData = resdata;
        this.copyRight = this.footerData.footer.copyright;
        this.footerLinks = this.footerData.footer.links;
        // console.log(this.footerLinks);
        this.footerClass = this.footerData.footer.class;
      });
  }
}
