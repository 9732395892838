import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ContentService } from '../service/content.service';

@Component({
  selector: 'app-category',
  templateUrl: './category.component.html',
  styleUrls: ['./category.component.css']
})
export class CategoryComponent implements OnInit {

  category = [];
  getCatData: any;
  subCategory = [];
  paramObj = [];
  sku = '';


  page = 1;
  count = 0;
  tableSize = 10;
  tableSizes = [10, 1];

  constructor(private categoryService: ContentService, private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.route.paramMap
      .subscribe(params => {
        this.paramObj.push(params);
        this.sku = this.paramObj[0].params.item;
        // console.log(this.paramObj);
      });

    this.categoryList();
  }

  categoryList() {
    // console.log(this.sku);
    this.category = this.categoryService.getCategory();
    // console.log(this.category);
    this.categoryService.pageContents(this.category[0].product_view_url)
      .subscribe(resdata => {
        this.getCatData = resdata;
        // console.log(this.getCatData);
        // console.log(this.getCatData.products[0].reference);

        // tslint:disable-next-line:prefer-for-of
        for (let i = 0; i < this.getCatData.products.length; i++) {
          if (this.getCatData.products[i].reference !== this.sku) {
            this.subCategory.push(this.getCatData.products[i]);
          }
        }
        // console.log(this.subCategory);
      });
  }

  onTableDataChange(event){
    this.page = event;
    this.subCategory = this.subCategory;
  }

  onTableSizeChange(event): void {
    this.tableSize = event.target.value;
    this.page = 1;
    this.subCategory  = this.subCategory;
  }
}
