import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { Routes, RouterModule} from '@angular/router';
import { HttpClientModule} from '@angular/common/http';
import { NgxPaginationModule } from 'ngx-pagination';

import { AppComponent } from './app.component';
import { NgbCollapseModule, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { HeaderService } from './service/header.service';
import { HeaderComponent } from './header/header.component';
import { ContentService } from './service/content.service';
import { FooterComponent } from './footer/footer.component';
import { MainContentComponent } from './main-content/main-content.component';
import { MainContent } from './common/main-content';
import { CarouselComponent } from './carousel/carousel.component';
import { ArticleComponent } from './article/article.component';
import { ContactComponent } from './contact/contact.component';
import { FormsModule } from '@angular/forms';
import { ContactService } from './service/contact.service';
import { ProductComponent } from './product/product.component';
import { ProductViewComponent } from './product-view/product-view.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { ProductCategoryComponent } from './product-category/product-category.component';
import { BrandsComponent } from './brands/brands.component';
import { ArticleListComponent } from './article-list/article-list.component';
import { RecaptchaDirective } from './directives/recaptcha.directive';
import { ApiError } from './common/api-error';
import { ArticleViewComponent } from './article-view/article-view.component';
import { BrandViewComponent } from './brand-view/brand-view.component';
import { CategoryComponent } from './category/category.component';
import { AddressComponent } from './address/address.component';
import { FormComponent } from './form/form.component';
import { CategoryMenuComponent } from './category-menu/category-menu.component';
import { CategoryListComponent } from './category-list/category-list.component';
import { NewsEventComponent } from './news-event/news-event.component';
import { ProjectComponent } from './project/project.component';
import { FacebookModule } from 'ngx-facebook';
import { InquiryComponent } from './inquiry/inquiry.component';
import { InnerLinkProductComponent } from './inner-link-product/inner-link-product.component';
import { InnerProductViewComponent } from './inner-product-view/inner-product-view.component';
import { InnerLinkCategoriesComponent } from './inner-link-categories/inner-link-categories.component';
import { TabsComponent } from './tabs/tabs.component';
import { SanitizeHtmlPipe } from './common/bypass';
import { LoanCalculatorComponent } from './loan-calculator/loan-calculator.component';
import { InquiryFormComponent } from './inquiry-form/inquiry-form.component';


const routes: Routes = [
  {path: ':name', component: MainContentComponent},
  {path: ':name/:item', component: MainContentComponent},
  {path: ':name/:item/:id', component: MainContentComponent},
  {path: '', redirectTo: 'home', pathMatch: 'full'}
];

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    MainContentComponent,
    CarouselComponent,
    ArticleComponent,
    MainContent,
    ContactComponent,
    ProductComponent,
    ProductViewComponent,
    PageNotFoundComponent,
    ProductCategoryComponent,
    BrandsComponent,
    ArticleListComponent,
    RecaptchaDirective,
    ArticleViewComponent,
    BrandViewComponent,
    CategoryComponent,
    AddressComponent,
    FormComponent,
    CategoryMenuComponent,
    CategoryListComponent,
    NewsEventComponent,
    ProjectComponent,
    InquiryComponent,
    InnerLinkProductComponent,
    InnerProductViewComponent,
    InnerLinkCategoriesComponent,
    TabsComponent,
    SanitizeHtmlPipe,
    LoanCalculatorComponent,
    InquiryFormComponent
  ],
  imports: [
    BrowserModule,
    NgbModule,
    RouterModule.forRoot(routes),
    HttpClientModule,
    FormsModule,
    NgxPaginationModule,
    NgbCollapseModule,
    FacebookModule.forRoot()
  ],
  providers: [
    HeaderService,
    ContentService,
    MainContent,
    ContactService,
    ApiError
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
