import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ContentService } from '../service/content.service';
import { SiteInfoService } from '../service/site-info.service';

@Component({
  selector: 'app-address',
  templateUrl: './address.component.html',
  styleUrls: ['./address.component.css']
})
export class AddressComponent implements OnInit {

  addressContent: any;
  extractAddress = [];
  address = [];
  addressTitle = '';
  mainAddress = '';
  contact1 = '';
  contact2 = '';
  email = '';

  themeStyle: any;
  urlMap: any;

  constructor(private serviceContent: ContentService, private siteInfo: SiteInfoService, private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.getAddress();
    this.getTheme();

    // console.log(localStorage.getItem('home'));
  }

  getAddress() {
    this.serviceContent.pageContents('/page/contact-us')
      .subscribe(resdata => {
        this.addressContent = resdata;
        this.extractAddress.push(this.addressContent.page.sections);
        // console.log(this.extractAddress);
        try {
          // tslint:disable-next-line:prefer-for-of
          for (let i = 0; i < this.extractAddress.length; i++) {
            if (this.extractAddress[i][i].name === 'Contact Us') {
              this.address.push(this.extractAddress[i][i].contents);
            }
          }
          // console.log(this.address[0][0].title);
          this.addressTitle = this.address[0][0].title;
          this.mainAddress = this.address[0][0].address;
          this.contact1 = this.address[0][0].contact_number_1;
          this.contact2 = this.address[0][0].contact_number_2;
          this.email = this.address[0][0].email;
        } catch (error) {
          console.log(error);
        }
      });
  }

  getTheme() {
    this.siteInfo.getSiteInfo()
      .subscribe(resdata => {
        this.themeStyle = resdata;
        this.themeStyle = this.themeStyle.site_info.theme.code;
        // console.log(this.themeStyle);
      });
  }
}
