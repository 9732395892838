import { Component, OnInit } from '@angular/core';
import { ContentService } from '../service/content.service';

@Component({
  selector: 'app-inner-link-categories',
  templateUrl: './inner-link-categories.component.html',
  styleUrls: ['./inner-link-categories.component.css']
})
export class InnerLinkCategoriesComponent implements OnInit {

  innCat: any;

  constructor(private categoryService: ContentService) { }

  ngOnInit(): void {
    this.innCat = this.categoryService.getPageContent();
    console.log(this.innCat);
  }
}
