<section>
  <div class="container">
    <div class="row shadow-sm" *ngFor="let article of articles | paginate : {
      itemsPerPage: tableSize,
      currentPage: page,
      totalItems: count
    };
    let i = index" [class.active]="i == currentIndex" class="art-list">
      <!-- <div class="col alert alert-dark" [class.impian-article]="themeStyle === 'impian_emas'">
        <h3>{{ article.title }}</h3>
        <p class="text-justify" [innerHTML]="article.body | slice:0:300">...</p>
        <a class="btn btn-secondary shadow" role="button" [routerLink]="article.view_url">Read More</a>
      </div> -->

      <div class="row mb-2">
        <div class="col-md">
          <div class="card flex-md-row mb-4 box-shadow h-md-250">
            <div class="card-body d-flex flex-column align-items-start">
              <h3 class="mb-0">
                {{ article.title }}
              </h3>
              <p id="art-margin" class="text-justify" [innerHTML]="(article.body | slice:0:300) + ' ...'"></p>
              <a class="btn btn-secondary shadow" role="button" [routerLink]="article.view_url">Read More</a>
            </div>
            <img class="card-img-right flex-auto d-none d-md-block" [class.impian-card]="themeStyle === 'impian_emas'" [src]="article.image" alt="Card image cap">
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="d-flex justify-content-center">
    <pagination-controls
      responsive="true"
      previousLabel="Prev"
      nextLabel="Next"
      (pageChange)="onTableDataChange($event)">
    </pagination-controls>
  </div>
</section>
