import { Component, OnInit } from '@angular/core';
import { ContentService } from '../service/content.service';

@Component({
  selector: 'app-product-view',
  templateUrl: './product-view.component.html',
  styleUrls: ['./product-view.component.css']
})
export class ProductViewComponent implements OnInit {

  prodViewData: any;
  viewProduct: any;
  ralatedCategory = [];
  imgUrl = '';
  prodName = '';
  prodDesc = '';

  constructor(private viewProductData: ContentService) { }

  ngOnInit(): void {
    this.prodViewData = this.viewProductData.getPageContent();
    console.log(this.prodViewData.product.product_images.length);
    this.viewProduct = this.prodViewData.product;
    this.ralatedCategory = this.viewProduct.categories;
    this.viewProductData.setCategory(this.ralatedCategory);
    // console.log(this.prodViewData);
  }
}

