<section>
  <ul ngbNav #nav="ngbNav" [(activeId)]="active" class="nav-pills" [destroyOnHide]="false" class="nav-tabs">
    <li [ngbNavItem]="1" [destroyOnHide]="true">
      <a ngbNavLink>Introduction</a>
      <ng-template ngbNavContent>
        <div id="product-view">
          <div class="card">
            <div class="container-fliud">
              <div class="wrapper row">
                <div class="details col-md-12 inn-product">
                  <h3 class="product-title">{{ viewTabData.name }}</h3>
                  <div [innerHTML]="viewTabData.description" class="tab-plugin"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ng-template>
    </li>
    <li [ngbNavItem]="2" *ngIf="viewTabData.location !== ''">
      <a ngbNavLink>Location</a>
      <ng-template ngbNavContent>
        <div id="product-view">
          <div class="card">
            <div class="container-fliud">
              <div class="wrapper row">
                <div class="details col-md-12 inn-product">
                  <h3 class="product-title">{{ viewTabData.name }}</h3>
                  <div [innerHTML]="viewTabData.location" class="tab-plugin"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ng-template>
    </li>
    <li [ngbNavItem]="3" *ngIf="viewTabData.specifications !== ''">
      <a ngbNavLink>Specifications</a>
      <ng-template ngbNavContent>
        <div id="product-view">
          <div class="card">
            <div class="container-fliud">
              <div class="wrapper row">
                <div class="details col-md-12 inn-product">
                  <h3 class="product-title">{{ viewTabData.name }}</h3>
                  <div [innerHTML]="viewTabData.description" class="tab-plugin"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ng-template>
    </li>
    <li [ngbNavItem]="4" *ngIf="viewTabData.floor_plan !== ''">
      <a ngbNavLink>Floor Plan</a>
      <ng-template ngbNavContent>
        <div id="product-view">
          <div class="card">
            <div class="container-fliud">
              <div class="wrapper row">
                <div class="details col-md-12 inn-product">
                  <h3 class="product-title">{{ viewTabData.name }}</h3>
                  <div [innerHTML]="viewTabData.description" class="tab-plugin"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ng-template>
    </li>
    <li [ngbNavItem]="5" *ngIf="viewTabData.gallery !== ''">
      <a ngbNavLink>Gallery</a>
      <ng-template ngbNavContent let-active>
        <p>Gallery</p>
      </ng-template>
    </li>
  </ul>
</section>


<div [ngbNavOutlet]="nav" class="mt-2"></div>

<!-- <pre>Active: {{ active }}</pre> -->
