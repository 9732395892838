<div *ngIf="themeStyle === 'impian_emas'; then thenBlock else elseBlock"></div>
<ng-template #thenBlock>
  <div id="footer-holder">
  <footer class="footer p-2 m-0" [class]="footerClass" id="footer">
    <div id="footer-holder">
        <div class="content-desktop" id="footer-visibility">
          <div id="footer-cust">
            <div class="row" id="footer-content">
              <div class="col-sm-6" id="footer-add">
                <img src="assets/image/logo.png" alt="" id="footer-logo">
                <div id="footer-address">
                  <app-address></app-address>
                </div>
              </div>
              <div class="col-sm-6 text-right">
                <div id="footer-right">
                  <p class="footer-links">FOLLOW US ON INSTAGRAM<br>@impianemas.lifecircle</p>
                </div>
                <div class="footer-links" id="footer-url">
                  <ul>
                    <li><a href="">ABOUT US</a></li>
                    <li><a href="">CONTACT US</a></li>
                    <li><a href="">MAKE AN APPOINTMENT</a></li>
                    <li><a href="" target="_blank">MEDIA APPEARNACE</a></li>
                    <li><a href="" target="_blank">TERMS & CONDITION</a></li>
                    <li><a href="" target="_blank">PRIVACY POLICY</a></li>
                </ul>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="content-mobile" id="footer-mobile">
          <div id="footer-cust text-center">
            <div class="row" id="footer-content">
              <div id="text-center" id="footer-insta">
                <p class="footer-links text-center">FOLLOW US ON INSTAGRAM<br>@impianemas.lifecircle</p>
              </div>
              <div class="col-sm-6">
                <img src="assets/image/logo.png" alt="" id="footer-logo">
                <div id="footer-mobile-address">
                  <app-address></app-address>
                </div>
              </div>
              <div class="col-sm-6 text-center">
                <div class="footer-links" id="footer-url">
                  <ul>
                    <li><a href="">ABOUT US</a></li>
                    <li><a href="">CONTACT US</a></li>
                    <li><a href="">MAKE AN APPOINTMENT</a></li>
                    <li><a href="" target="_blank">MEDIA APPEARNACE</a></li>
                    <li><a href="" target="_blank">TERMS & CONDITION</a></li>
                    <li><a href="" target="_blank">PRIVACY POLICY</a></li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
    </div>
  </footer>
  </div>
</ng-template>
<ng-template #elseBlock>
  <footer class="footer" [class]="footerClass">
    <div class="container text-center">
      <nav class="navbar navbar-expand-sm navbar-light ">
        <div class="navbar-nav d-md-flex d-block flex-row mx-md-auto mx-0 d-inline-flex" id="footer-link">
          <a
          *ngFor="let url of footerLinks"
          class="nav-link" [routerLink]="url.url">{{ url.name }}</a>
        </div>
        <div
        *ngIf="errSpinner"
        class="spinner-border text-center"></div>
      </nav>
      <span class="text-muted" [innerHTML]="copyRight"></span>
    </div>
  </footer>
</ng-template>
