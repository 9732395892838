<section>
  <p>{{ this.category[0].label }}</p>
  <div class="row" id="product">
    <div class="col-md-3" id="product-inner" *ngFor="let list of subCategory | paginate : {
      itemsPerPage: tableSize,
      currentPage: page,
      totalItems: count
    };
    let i = index" [class.active]="i == currentIndex" >
      <div class="card mb-4 shadow-lg" id="cust-cat">
        <img *ngFor="let img of list.product_images" class="card-img-top img-fluid" [src]="img.image_url" alt="Card image cap">
        <div class="card-body">
          <h5 class="card-text"><strong> {{ list.name }} </strong></h5>
          <!-- <p>{{ list.description | striphtml }}</p> -->
          <div class="d-flex justify-content-between align-items-center">
            <div class="btn-group">
              <a class="btn btn-secondary shadow" role="button" [routerLink]="list.view_url">Read More</a>
            </div>
            <small class="text-muted"></small>
          </div>
        </div>
        <!-- <div class="overlay d-flex align-items-center justify-content-center"><a class="btn btn-secondary" role="button" [routerLink]="list.view_url" (click)="test()" data-abc="true">View Details</a></div> -->
      </div>
    </div>
  </div>
  <div class="d-flex justify-content-center">
    <pagination-controls
      responsive="true"
      previousLabel="Prev"
      nextLabel="Next"
      (pageChange)="onTableDataChange($event)">
    </pagination-controls>
  </div>
</section>

