
<section>
  <div class="container">
    <div class="tabs">
    </div>
    <div class="art-list" [class.impian-view-article]="themeStyle === 'impian_emas'">
      <h1>{{ articleTitle }}</h1>
    <!-- <p class="text-justify">{{ articleBody | striphtml }}</p> -->
      <div id="art-image" [innerHTML]="articleBody"></div>
    </div>
  </div>
</section>
