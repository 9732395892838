<section>
  <div class="container" id="product-view">
		<div class="card">
			<div class="container-fliud">
				<div class="wrapper row">
					<div class="preview col-md-12">
						<div class="preview-pic tab-content">
              <div *ngIf="this.prodViewData.product.product_images.length > 0; then thenBlock else elseBlock"></div>
              <ng-template #thenBlock>
                <div class="text-center" id="prod-img">
                  <img class="rounded" [src]="viewProduct.product_images[0].image_url" />
                </div>
              </ng-template>
              <ng-template #elseBlock>
                <!-- <img class="rounded" src="https://via.placeholder.com/150" /> -->
              </ng-template>
            </div>
          </div>
					<div class="details col-md-12">
						<h3 class="product-title">{{ viewProduct.name }}</h3>
            <div [innerHTML]="viewProduct.description" id="prod-desc"></div>
					</div>
				</div>
			</div>
		</div>
    <!-- <div class="container" id="related-products">
      <h6><strong>RELATED PRODUCT</strong></h6>
      <app-category></app-category>
    </div> -->
  </div>
</section>
