import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { GlobalConstants } from '../common/global-constants';
import { catchError } from 'rxjs/operators';
import { Observable } from 'rxjs';

import { ApiError } from '../common/api-error';

@Injectable({
  providedIn: 'root'
})
export class HeaderService {

  endpoint = GlobalConstants.apiURL + '/header?token=' + GlobalConstants.apiToken  ;

  constructor(private httpClient: HttpClient, private apiError: ApiError) { }

  getHeader(): Observable<any> {
    return this.httpClient.get(this.endpoint).pipe(
      catchError(this.apiError.handleError)
    );
  }
}
