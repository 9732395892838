import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError } from 'rxjs/operators';
import { ApiError } from '../common/api-error';
import { GlobalConstants } from '../common/global-constants';

@Injectable({
  providedIn: 'root'
})
export class LanguagesService {

  page = '/available-languages?token=';
  endPoint = GlobalConstants.apiURL + this.page + GlobalConstants.apiToken;

  constructor(private httpClient: HttpClient, private apiError: ApiError) { }

  getLang() {
    return this.httpClient.get(this.endPoint).pipe(
      catchError(this.apiError.handleError)
    );
  }
}
