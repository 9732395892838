import { Component, OnInit } from '@angular/core';
import { ContentService } from '../service/content.service';

@Component({
  selector: 'app-project',
  templateUrl: './project.component.html',
  styleUrls: ['./project.component.css']
})
export class ProjectComponent implements OnInit {

  projectCont: any;
  projData = [];
  projContents: any;

  constructor(private projectService: ContentService) { }

  ngOnInit(): void {
    this.getProject();
  }

  getProject() {
    this.projectCont = this.projectService.getPageContent();
    const result = this.extractProject(this.projectCont);
    this.projData.push(result);
    this.projContents = this.projData[0].contents[0];
    console.log(this.projContents);
  }

  extractProject(data: any) {
    for (const proj of data) {
      if (proj.name === 'Our Project') {
        return proj;
      }
    }
  }
}
