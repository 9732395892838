<div class="container">
  <form class="form-inline">
    <div class="row">
      <div class="col">
        <div class="form-group">
          <div class="input-group">
            <input class="form-control" placeholder="Date"
                   name="dp" [(ngModel)]="model1" ngbDatepicker #d1="ngbDatepicker" id="date">
            <div class="input-group-append">
              <button class="btn btn-outline-secondary calendar" (click)="d1.toggle()" type="button"></button>
            </div>
          </div>
        </div>
      </div>
      <div class="col">
        <div class="form-group">
          <div class="input-group">
            <input class="form-control" placeholder="Date of Birth"
                   name="dp" [(ngModel)]="model2" ngbDatepicker #d2="ngbDatepicker">
            <div class="input-group-append">
              <button class="btn btn-outline-secondary calendar" (click)="d2.toggle()" type="button"></button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">

    </div>
  </form>
</div>
