import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError } from 'rxjs/operators';
import { ApiError } from '../common/api-error';
import { GlobalConstants } from '../common/global-constants';

@Injectable({
  providedIn: 'root'
})
export class SiteInfoService {

  siteInfo: any;

  endpoint = GlobalConstants.apiURL + '/site-info?token=' + GlobalConstants.apiToken;

  constructor(private httpClient: HttpClient, private apiError: ApiError) { }

  getSiteInfo() {
    return this.httpClient.get(this.endpoint).pipe(
      catchError(this.apiError.handleError)
    );
  }

  setSiteInfo(data: any) {
    this.siteInfo = data;
  }

  getInfo() {
    return this.siteInfo;
  }
}
