import { Component, OnInit } from '@angular/core';
import { ContentService } from '../service/content.service';

@Component({
  selector: 'app-news-event',
  templateUrl: './news-event.component.html',
  styleUrls: ['./news-event.component.css']
})
export class NewsEventComponent implements OnInit {

  newsdata: any;
  newsarticles = [];
  newsContents: any;
  latest = [];
  artitle = '';
  artbody = '';
  arturl = '';

  constructor(private newsArticle: ContentService) { }

  ngOnInit(): void {
    this.getNews();
  }

  getNews() {
    // this.newsArticle.pageContents('/articles')
    //   .subscribe(resdata => {
    //     this.newsdata = resdata;
    //     this.newsarticles = this.newsdata.articles;
    //     this.latest.push(this.newsarticles[0]);
    //     this.artitle = this.latest[0].title;
    //     this.artbody = this.latest[0].body;
    //     this.arturl = this.latest[0].view_url;
    //     // console.log(this.newsdata);
    //   });

    this.newsdata = this.newsArticle.getPageContent();
    console.log(this.newsdata);
    for (const news of this.newsdata) {
      if (news.name === 'Latest News & Event') {
        this.newsContents = news.contents[0];
        console.log(this.newsContents);
      }
    }
  }
}
