<div class="row" id="contact">
    <div class="col-sm">
        <app-form></app-form>
    </div>
    <div class="col-sm">
      <app-address></app-address>
    </div>
</div>

<!-- <div>
  <app-inquiry></app-inquiry>
</div> -->
