import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-inner-product-view',
  templateUrl: './inner-product-view.component.html',
  styleUrls: ['./inner-product-view.component.css']
})
export class InnerProductViewComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
