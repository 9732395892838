<section>
  <div class="container-fluid text-center" id="project">
    <div id="project-desktop" >
      <div id="project-content" [innerHTML]="projContents.body">
        <!-- <div class="row proj-icons">
          <div class="col-md-4 col-sm-6">
            <img src="assets/image/impian-heights.jpg" alt="">
          </div>
          <div class="col-md-4 col-sm-6">
            <img src="assets/image/bukit-impian.jpg" alt="">
          </div>
          <div class="col-md-4 col-sm-6">
            <img src="assets/image/iconia.jpg" alt="">
          </div>
          <div class="col-md-4 col-sm-6">
            <img src="assets/image/impian-avenue.jpg" alt="">
          </div>
          <div class="col-md-4 col-sm-6">
            <img src="assets/image/impian-biz.jpg" alt="">
          </div>
          <div class="col-md-4 col-sm-6">
            <img src="assets/image/impian-link.jpg" alt="">
          </div>
        </div> -->
      </div>
    </div>

    <div id="project-mobile">
      <div class="row proj-icons" [innerHTML]="projContents.body">
        <!-- <div class="col">
          <img src="assets/image/impian-heights.jpg" alt="">
        </div>
        <div class="col">
          <img src="assets/image/bukit-impian.jpg" alt="">
        </div>
      </div>
      <div class="row proj-icons">
        <div class="col">
          <img src="assets/image/iconia.jpg" alt="">
        </div>
        <div class="col">
          <img src="assets/image/impian-avenue.jpg" alt="">
        </div>
      </div>
      <div class="row proj-icons">
        <div class="col">
          <img src="assets/image/impian-biz.jpg" alt="">
        </div>
        <div class="col">
          <img src="assets/image/impian-link.jpg" alt="">
        </div> -->
      </div>
    </div>
  </div>
</section>
