<div class="container">
  <div class="card">
    <div class="container-fliud">
      <div class="wrapper row">
        <div class="preview col-md-6">
          <div class="preview-pic tab-content">
            <div class="tab-pane active" id="pic-1"><img [src]="imgUrl" /></div>
            <div class="tab-pane" id="pic-2"><img [src]="imgUrl" /></div>
            <div class="tab-pane" id="pic-3"><img [src]="imgUrl" /></div>
            <div class="tab-pane" id="pic-4"><img [src]="imgUrl" /></div>
            <div class="tab-pane" id="pic-5"><img [src]="imgUrl" /></div>
          </div>
          <ul class="preview-thumbnail nav nav-tabs">
            <li class="active"><a data-target="#pic-1" data-toggle="tab"><img [src]="imgUrl" /></a></li>
            <li><a data-target="#pic-2" data-toggle="tab"><img [src]="imgUrl" /></a></li>
            <li><a data-target="#pic-3" data-toggle="tab"><img [src]="imgUrl" /></a></li>
            <li><a data-target="#pic-4" data-toggle="tab"><img [src]="imgUrl" /></a></li>
            <li><a data-target="#pic-5" data-toggle="tab"><img [src]="imgUrl" /></a></li>
          </ul>
        </div>
        <div class="details col-md-6">
          <h3 class="product-title">{{ viewBrandTitle }}</h3>
          <p class="product-description text-justify"><strong>Description:</strong><br> {{ viewBrandDesc | striphtml }}</p>
        </div>
      </div>
    </div>
  </div>
</div>
