<section>
  <div class="container" id="brand-desktop">
    <div class="row" id="brands">
      <div class="col-md-6" id="product-inner" *ngFor="let list of brandsContent | paginate : {
        itemsPerPage: tableSize,
        currentPage: page,
        totalItems: count
      };
      let i = index" [class.active]="i == currentIndex" >
        <div class="card mb-4 shadow-lg" id="cust-brand">
          <img class="card-img-top img-fluid" [src]="list.image" alt="Card image cap">
          <div class="card-body">
            <h5 class="card-text"> {{ list.name }} </h5>
            <div class="d-flex justify-content-between align-items-center">
              <div class="btn-group">
                <a class="btn btn-secondary shadow" role="button" [routerLink]="list.view_url">View Brand</a>
              </div>
              <small class="text-muted"></small>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="d-flex justify-content-center">
      <pagination-controls
        responsive="true"
        previousLabel="Prev"
        nextLabel="Next"
        (pageChange)="onTableDataChange($event)">
      </pagination-controls>
    </div>
  </div>
</section>
