import { Component, Input, OnInit } from '@angular/core';
import { ContentService } from '../service/content.service';

@Component({
  selector: 'app-product-category',
  templateUrl: './product-category.component.html',
  styleUrls: ['./product-category.component.css']
})
export class ProductCategoryComponent implements OnInit {

  @Input()  catLink = '';

  productData: any;
  prodList = [];
  prodContent = [];
  arrCont = [];
  mainCont = [];
  brand = [];
  listOrder = '';
  searchProd = false;

  page = 1;
  count = 0;
  tableSize = 10;
  tableSizes = [10, 1];

  innerLinkData: any;

  constructor(private contentService: ContentService) { }

  ngOnInit(): void {
    this.productData = [];
    this.mainCont = [];
    this.contentService.pageContents(this.catLink)
      .subscribe(resdata => {
        this.productData = resdata;
        this.prodList = this.productData.products;
        this.listOrder = this.productData.paginator.direction;
        console.log(this.prodList.length);

        for (let i = 0; i < this.prodList.length; i++) {
          this.prodContent = this.prodList[i].product_images;
          this.arrCont = this.arrCont.concat(this.prodList[i].product_images);
          this.mainCont.push(this.prodList[i]);
          this.brand.push(this.productData.products[i].brand);
        }
      });

  }

  onTableDataChange(event){
    this.page = event;
    this.mainCont = this.mainCont;
  }

  onTableSizeChange(event): void {
    this.tableSize = event.target.value;
    this.page = 1;
    this.mainCont  = this.mainCont;
  }

  sort(data) {
    this.mainCont.sort((a, b) => {
      const fa = a.name.toLowerCase();
      const fb = b.name.toLowerCase();

      switch (data){
        case 'DESC': {
          if (fa > fb) {
            return -1;
          }
          if (fa < fb) {
            return 1;
          }
          break;
        }
        case 'ASC': {
            if (fa < fb) {
              return -1;
            }
            if (fa > fb) {
              return 1;
            }
            break;
        }
        default: {
          return 0;
        }
      }
    });
  }

}
